import React, { useState } from 'react';
import classNames from 'classnames';
import Collapse from '@kunukn/react-collapse';
import styles from './styles/Accordion.module.scss';

const Accordion = ({
  className,
  contentClassName,
  titleClassName,
  content,
  title,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={classNames(styles.container, className)}>
      <div className={classNames(styles.title, titleClassName)}>{title}</div>
      <Collapse
        isOpen={open}
        className={classNames(
          'collapse-css-transition',
          styles.content,
          contentClassName,
          open && styles.open,
          open && 'open',
        )}
      >
        {content}
      </Collapse>
      <div
        className={classNames(styles.arrow, open && styles.rotate)}
        onClick={() => setOpen(!open)}
      >
        <i className="fa-solid fa-chevron-down"></i>
      </div>
    </div>
  );
};

export default Accordion;
