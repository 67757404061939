import React from 'react';
import ContentPillar from '../../components/ContentPillar';
import SEO from '../../components/seo';
import Layout from '../../components/Layout';
import useContentfulContentPillar from '../../hooks/useContentfulContentPillar';
import styles from '../styles/insurance-adviser-guide.module.scss';

const InsuranceAdviserGuide = () => {
  const data = useContentfulContentPillar('insurance-adviser');
  return (
    <Layout
      className={styles.container}
      mini={true}
      footerClassName={styles.footer}
    >
      <SEO title="Insurance Adviser Guide" />
      <ContentPillar data={data} sectionClassName={styles.section} />
    </Layout>
  );
};

export default InsuranceAdviserGuide;
