import React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import styles from './styles/ContentPillar.module.scss';
import CurveDownSvg from '../images/layout/curve-down.svg';
import Accordion from '../components/Accordion';
import LinkButton from '../components/LinkButton';
import { GET_STARTED_MS_BROKER_ROUTE } from '../constants/routes';
import { getContentfulRenderOptions } from '../common/contentful';
import BackToTop from './BackToTop';

const banner = (
  <div className={styles.banner}>
    <h4>Ready to sign up with our insurance adviser?</h4>
    <LinkButton
      href={GET_STARTED_MS_BROKER_ROUTE}
      background="#4187F5"
      className={styles.blueBtn}
    >
      Get started
    </LinkButton>
  </div>
);

const getTitles = (data) => {
  const titles = [];

  for (let i = 0; i < 10; i++) {
    const title = data[`sectionTitle${i + 1}`];
    if (title) {
      titles.push(
        <a
          href={`#${title
            .toLowerCase()
            .split(' ')
            .join('-')}`}
        >
          {title}
        </a>,
      );
    }
  }

  return titles;
};

const getSections = (data) => {
  const sections = [];
  for (let i = 0; i < 10; i++) {
    const num = i + 1;
    const title = data[`sectionTitle${num}`];
    if (title) {
      const content = data[`sectionContent${num}`];
      sections.push({
        num,
        id: title
          .toLowerCase()
          .split(' ')
          .join('-'),
        content: documentToReactComponents(
          content.json,
          getContentfulRenderOptions(),
        ),
        title,
      });
    }
  }
  return sections;
};

const ContentPillar = ({ data, sectionClassName, tableOfContentsMin = 3 }) => {
  const { readTime, updatedAt, title, titleCover, titleDescription } = data;
  const titles = getTitles(data);
  const sections = getSections(data);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className="container">
          <div className={styles.hero}>
            <img alt={titleCover.title} src={titleCover.fluid.src} />
            <div className={styles.heroTitle}>
              <h2>{title}</h2>
              <p>
                <time>Updated {moment(updatedAt).format('MMMM DD, YYYY')}</time>{' '}
                | <span>{`${readTime} min read`}</span>
              </p>
            </div>
          </div>
          <div className={styles.desc}>
            {documentToReactComponents(titleDescription.json)}
          </div>
        </div>
      </div>
      <div className={styles.tocContainer}>
        <div className={styles.tocBg} />
        <CurveDownSvg className={styles.whiteCurve} />
        <Accordion
          className={styles.tableOfContents}
          contentClassName={styles.tocContent}
          content={
            <ol start={tableOfContentsMin + 1}>
              {titles.slice(tableOfContentsMin, titles.length).map((t, idx) => (
                <li key={`content_${idx}`}>{t}</li>
              ))}
            </ol>
          }
          title={
            <>
              <div className={styles.tocHeader}>In this guide</div>
              <ol>
                {titles.slice(0, tableOfContentsMin).map((t, idx) => (
                  <li key={`title_${idx}`}>{t}</li>
                ))}
              </ol>
            </>
          }
        />
      </div>
      <div className={styles.sections}>
        {banner}
        {sections.map((section, idx) => {
          return (
            <React.Fragment key={section.id}>
              <section
                id={section.id}
                className={classNames(
                  styles.section,
                  'container',
                  sectionClassName,
                )}
              >
                <h3 className={styles.sectionNum}>
                  <span>{section.num + '.'}</span>
                </h3>
                <h3 className={styles.sectionTitle}>{section.title}</h3>
                <div className={styles.sectionContent}>{section.content}</div>
                {(idx === 3 || idx === sections.length - 1) && (
                  <BackToTop className={styles.backToTop} />
                )}
              </section>
              {idx === 3 && banner}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default ContentPillar;
