import classNames from 'classnames';
import React from 'react';
import styles from './styles/BackToTop.module.scss';

const BackToTop = ({ className }) => {
  return (
    <div className={classNames(styles.backToTop, className)}>
      <span
        onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}
      >
        Back to the top{' '}
        <i className="fa-solid fa-chevron-up" aria-hidden="true"></i>
      </span>
    </div>
  );
};

export default BackToTop;
