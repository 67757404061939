import React, { useRef } from 'react';
import Slider from 'react-slick';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import ContentfulLinks from '../components/ContentfulLinks';
import styles from './contentful.module.scss';
import classNames from 'classnames';

const ImgAsset = (node) => {
  if (!node.data.target.fields) return null;
  const { file } = node.data.target.fields;
  const url = file['en-US'].url;
  return <img src={`https://${url}`} style={{ width: '100%' }} />;
};

const getContentText = (acc, { value }) => `${acc ? acc + ' ' : ''}${value}`;

const getSlideContent = (node, id) => {
  const x = node.content.reduce((acc, row, cIdx) => {
    row.content.forEach((c, itemIdx) => {
      const value = c.content[0].content.reduce(getContentText, '');
      const prevValues = acc[itemIdx] || [];
      const className =
        c.nodeType === BLOCKS.TABLE_HEADER_CELL
          ? classNames(styles.th, 'table-slider-th')
          : classNames(styles.td, 'table-slider-td');

      acc[itemIdx] = [
        ...prevValues,
        <div className={className} key={`${id}_${cIdx}_${itemIdx}`}>
          {value}
        </div>,
      ];
    });
    return acc;
  }, {});

  const values = Object.values(x);

  // When there is more than 2 columns, the first column should be sticky
  if (node.content[0].content.length > 2) {
    const [firstRows, ...otherRows] = values;
    return otherRows.map((rows, idx) => (
      <div key={`${id}_${idx}`} className={styles.twoColumn}>
        {firstRows.map((row, fIdx) => (
          <div key={`${id}_${idx}_${fIdx}`} className={styles.row}>
            {row}
            {rows[fIdx]}
          </div>
        ))}
      </div>
    ));
  }

  return values.map((rows, idx) => (
    <div key={`${id}_${idx}`} className={styles.column}>
      {rows}
    </div>
  ));
};

// table-row[] > table-header-cell / table-cell
const Table = (node, children) => {
  const { current: id } = useRef(Date.now());
  return (
    <>
      <table className={styles.table}>
        <tbody>{children}</tbody>
      </table>
      <Slider
        className={classNames(styles.tableSlider, 'table-slider')}
        arrows={false}
        infinite={true}
        dots={true}
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        swipeToSlide={true}
        nextArrow={false}
        prevArrow={false}
      >
        {getSlideContent(node, id)}
      </Slider>
    </>
  );
};

export const getContentfulRenderOptions = (options) => ({
  renderNode: {
    [BLOCKS.TABLE]: Table,
    [BLOCKS.EMBEDDED_ASSET]: ImgAsset,
    [INLINES.HYPERLINK]: ContentfulLinks,
    ...options,
  },
});
